@import "../variables";

.page-header {
  border-bottom: 8px solid @primary-lighter;
  background: @tertiary;
  display: inline-table;
  width: 100%;

  .inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    img {
      text-align: right;
      font-weight: 700;
      font-size: 36px;
      color: @primary;
      cursor: pointer;
      margin-left: -10rem;
    }
  }
}

body main {
  display: block;

  .inner-wrapper {
    width: 100%;

    .error-content {
      margin-top: 5rem;
      text-align: center;
      h1 {
        font-weight: 900;
        font-style: normal;
        font-size: 15rem;
        color: #76be43;
      }
      ol {
        list-style-type: none;
        margin: 0 auto;
        max-width: 85rem;
        li {
          &:not(:last-child) {
            border-bottom: 1px solid @tertiary;
          }
          font-weight: 700;
          font-style: normal;
          font-size: 2.2rem;
          color: #006833;
          text-align: center;
          padding: 3rem;
        }
      }
      .home-button {
        width: 10rem;
        height: 10rem;
        border: 1px solid @primary-lighter;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4rem;
        color: @primary-lighter;
        margin: 4rem auto;
        animation: bounce 1.5s cubic-bezier(0.65, 0.05, 1, 1);
        cursor: pointer;
        &:after {
          content: "\f015";
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
        }
        &:hover {
          background: @primary-lighter;
          color: white;
        }
      }
    }
  }
  .back {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    color: #000;
    line-height: 0;
    margin-left: 20rem;
    padding: 3rem;
    &:before {
      cursor: pointer;
      content: "\f104";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 2.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      color: @primary-lighter;
    }
  }
}

@media @ipad-landscape {
  .page-header {
    padding: 0 4%;
  }
}

@media @ipad-portrait {
  .page-header {
    .inner-wrapper {
      h1 {
        font-size: 18px;
      }
    }
  }
  body main {
    .inner-wrapper {
      flex-direction: column;
      text-align: center;
      .oops-container {
        .text-container {
          padding-right: 0;
        }
        .button {
          width: 100%;
        }
      }
      .image-container {
        margin-left: 0;
        margin-top: 80px;
      }
    }
  }
}

@media @mobile {
  body main {
    .inner-wrapper {
      .error-content {
        h1 {
          font-size: 10rem;
        }
        ol {
          li {
            font-size: 1.5rem;
          }
        }
        .home-button {
          width: 8rem;
          height: 8rem;
          font-size: 3rem;
        }
      }
    }
  }
}

//animations
@keyframes bounce {
  0% {
    transform: translateY(-10rem);
  }
  30% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(-1.5rem);
  }
  60% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-0.7rem);
  }
  100% {
    transform: translateY(0);
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .fallback-rules;
}

@supports not (display: grid) {
  .fallback-rules;
}

.fallback-rules {
  body {
    display: auto;
  }
}